import React from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import './Home.css';

const Home = () => {
  return (
    <>
      <Helmet>
        <title>Beat Contract Generator | Choose Your License Type</title>
        <meta name="description" content="Generate professional beat contracts for lease, exclusive, or beat pack licenses. Protect your music and streamline your production business." />
        <meta name="keywords" content="beat contracts, music licensing, lease license, exclusive license, beat pack license, music production" />
      </Helmet>
      
      <div className="home-container">
        <header>
          <h1 className="home-title">Beat Contract Generator</h1>
        </header>
        
        <main>
          <section>
            <h2>What License Do You Want to Generate?</h2>
            <div className="button-container">
              <Link to="/lease" className="home-button">Lease License</Link>
              <Link to="/exclusive" className="home-button">Exclusive License</Link>
              <Link to="/beatpack" className="home-button">Beat Pack License</Link>
            </div>
          </section>
          
          <section className="license-info">
            <h3>Understanding Our License Types</h3>
            <ul>
              <li><strong>Lease License:</strong> Non-exclusive rights to use the beat, ideal for upcoming artists.</li>
              <li><strong>Exclusive License:</strong> Full ownership transfer of the beat, perfect for established artists.</li>
              <li><strong>Beat Pack License:</strong> Bundle multiple beats with custom terms, great for albums or EP projects.</li>
            </ul>
          </section>
        </main>
      </div>
      
      <footer>
        <p>© 2024 Beat Contract Generator. All rights reserved.</p>
      </footer>
    </>
  );
};

export default Home;