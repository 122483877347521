import React, { useState, useRef } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import './KeywordFinder.css';
import confetti from 'canvas-confetti';
import SignatureCanvas from 'react-signature-canvas';

const LeaseContractGenerator = () => {
  const [step, setStep] = useState(1);
  const [logo, setLogo] = useState(null);
  const [logoPreview, setLogoPreview] = useState(null);
  const [beatInfo, setBeatInfo] = useState({
    name: '',
    leasePrice: '',
    copies: '',
    publishingRights: '',
  });
  const [producerInfo, setProducerInfo] = useState({
    legalName: '',
    producerName: '',
    address: '',
    state: '',
    country: 'United States of America',
  });
  const [artistInfo, setArtistInfo] = useState({
    legalName: '',
    stageName: '',
    address: '',
    country: 'United States of America',
  });
  const [agreed, setAgreed] = useState(false);
  const signatureCanvasRef = useRef(null);

  const handleNextStep = () => setStep(step + 1);
  const handlePrevStep = () => setStep(step - 1);

  const handleLogoUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setLogo(reader.result);
        setLogoPreview(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const clearSignature = () => {
    signatureCanvasRef.current.clear();
  };

  const generatePDF = () => {
    const doc = new jsPDF({
      orientation: 'portrait',
      unit: 'mm',
      format: 'a4'
    });
    
    const date = new Date().toLocaleDateString();
    const signatureData = signatureCanvasRef.current.toDataURL();
  
    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const margin = 20;
    const maxWidth = pageWidth - 2 * margin;
  
    let yPos = margin;
  
    // Add custom font
    doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf', 'Roboto', 'normal');
    doc.addFont('https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf', 'Roboto', 'bold');
  
    const addText = (text, fontSize = 11, isBold = false, align = 'left', color = '#000000') => {
      doc.setFontSize(fontSize);
      doc.setFont('Roboto', isBold ? 'bold' : 'normal');
      doc.setTextColor(color);
      const lines = doc.splitTextToSize(text, maxWidth);
      doc.text(lines, align === 'center' ? pageWidth / 2 : margin, yPos, { align: align });
      yPos += lines.length * (fontSize * 0.5);
    };
  
    const addSection = (title, content) => {
      checkAndAddPage();
      addText(title, 13, true, 'left', '#1a237e');
      yPos += 2;
      addText(content);
      yPos += 5;
    };
  
    const checkAndAddPage = () => {
      if (yPos > pageHeight - 40) {
        addPageNumber();
        doc.addPage();
        yPos = margin;
      }
    };
  
    const addPageNumber = () => {
      doc.setFontSize(9);
      doc.setTextColor('#666666');
      doc.text(`Page ${doc.internal.getNumberOfPages()}`, pageWidth - margin, pageHeight - 10);
    };
  
    // Header
    const headerHeight = 30;
    doc.setFillColor(248, 249, 250);
    doc.rect(0, 0, pageWidth, headerHeight, 'F');
    
    doc.setDrawColor(200, 200, 200);
    doc.setLineWidth(0.5);
    doc.line(0, headerHeight, pageWidth, headerHeight);
    
    doc.setTextColor(50, 50, 50);
    doc.setFont('Roboto', 'bold');
    doc.setFontSize(16);
    doc.text("LEASE LICENSE AGREEMENT", pageWidth / 2, headerHeight / 2, { 
      align: 'center', 
      baseline: 'middle'
    });
    
    yPos = headerHeight + 15;
    
    doc.setTextColor(0, 0, 0);
    
    // Add logo
    if (logo) {
      const logoWidth = 40;
      const logoHeight = 16;
      const logoX = margin;
      const logoY = headerHeight + 8;
      doc.addImage(logo, 'PNG', logoX, logoY, logoWidth, logoHeight);
      yPos = Math.max(yPos, logoY + logoHeight + 10);
    }
    
    // Add decorative line
    doc.setDrawColor(25, 118, 210);
    doc.setLineWidth(0.5);
    doc.line(margin, yPos, pageWidth - margin, yPos);
    yPos += 10;

    // Agreement introduction
    addText(`THIS LEASE LICENSE AGREEMENT (the "Agreement") is made and entered into on ${date} ("Effective Date") by and between:`, 11, false, 'left', '#333333');
    yPos += 5;
    addText(`${artistInfo.legalName} (the "Licensee")`, 11, true);
    addText(`Professional Name: ${artistInfo.stageName}`, 11, false, 'left', '#333333');
    addText(`Address: ${artistInfo.address}, ${artistInfo.country}`, 11, false, 'left', '#333333');
    yPos += 5;
    addText(`and`, 11, false, 'center', '#333333');
    yPos += 5;
    addText(`${producerInfo.legalName} (the "Licensor")`, 11, true);
    addText(`Professional Name: ${producerInfo.producerName}`, 11, false, 'left', '#333333');
    addText(`Address: ${producerInfo.address}, ${producerInfo.state}, ${producerInfo.country}`, 11, false, 'left', '#333333');
    yPos += 10;

    // Beat Information
    addText('BEAT INFORMATION', 13, true, 'left', '#1a237e');
    yPos += 2;
    addText(`Beat Name: ${beatInfo.name}`, 11, false, 'left', '#333333');
    addText(`License Price: $${beatInfo.leasePrice} USD`, 11, false, 'left', '#333333');
    addText(`Number of Copies: ${beatInfo.copies}`, 11, false, 'left', '#333333');
    addText(`Publishing Rights: ${beatInfo.publishingRights}% retained by Licensor`, 11, false, 'left', '#333333');
    yPos += 10;
  
    // License details
    addSection('1. Grant of License', 'Licensor hereby grants to Licensee a non-exclusive license to use the musical composition titled "' + beatInfo.name + '" (the "Beat") on the terms and conditions set forth in this Agreement.');
    addSection('2. Permitted Uses', `a) Record vocal synchronization to the Beat and substantially reproduce the Beat in the New Recording as demonstrated in the Master Recording.\nb) Distribute up to ${beatInfo.copies} copies of the New Recording via audio transfers.\nc) Perform the New Recording publicly for non-profit and non-commercial purposes.\nd) Use the New Recording in one (1) music video for non-commercial purposes.`);
    addSection('3. Restrictions', 'a) Licensee shall not sublicense, transfer, or assign the rights granted under this License.\nb) Licensee shall not use or permit the use of the Beat for any commercial purpose beyond the Permitted Uses without obtaining an additional license.');
    addSection('4. Ownership', 'Licensor retains all rights, title, and interest in the Beat, including all copyrights and other intellectual property rights. Licensee shall own the copyright in any original lyrics added to the Beat by Licensee.');
    addSection('5. Credit', `Licensee shall credit Licensor as the producer of the Beat in substantially the following form: "Prod. by ${producerInfo.producerName}" or "Beat by ${producerInfo.producerName}"`);
    addSection('6. Publishing and Royalties', `a) Licensor shall retain ${beatInfo.publishingRights}% of publishing rights.\nb) Licensee shall retain ${100 - beatInfo.publishingRights}% of publishing rights.`);
    addSection('7. Payment', `Licensee shall pay Licensor the sum of $${beatInfo.leasePrice} USD for this License.`);
    addSection('8. Term', 'This Agreement shall commence on the Effective Date and shall continue in perpetuity.');
    addSection('9. Governing Law', `This Agreement shall be governed by and construed in accordance with the laws of ${producerInfo.state}, ${producerInfo.country}.`);

    // Ensure we're on a new page for signatures
    if (yPos > pageHeight - 100) {
      addPageNumber();
      doc.addPage();
      yPos = margin;
    }
  
    // Add extra space before signatures
    yPos += 20;
  
    // Signatures
    const signatureY = pageHeight - 60;
    doc.setDrawColor(25, 118, 210);
    doc.line(margin, signatureY, margin + 70, signatureY);
    doc.text("Licensor Signature", margin, signatureY + 5);
    doc.line(pageWidth - margin - 70, signatureY, pageWidth - margin, signatureY);
    doc.text("Licensee Signature", pageWidth - margin - 70, signatureY + 5);
  
    // Add producer signature
    if (signatureData) {
      doc.addImage(signatureData, 'PNG', margin, signatureY - 25, 70, 20);
    }
  
    // Add final page number
    addPageNumber();
  
    // Footer
    doc.setFillColor(25, 118, 210);
    doc.rect(0, pageHeight - 15, pageWidth, 15, 'F');
    doc.setTextColor(255, 255, 255);
    doc.setFontSize(9);
    doc.text(`Generated on ${date} | Page ${doc.internal.getNumberOfPages()} of ${doc.internal.getNumberOfPages()}`, pageWidth / 2, pageHeight - 5, { align: 'center' });
  
    const fileName = `${beatInfo.name} Lease License Agreement by ${producerInfo.producerName}.pdf`;
    doc.save(fileName);
  
    confetti({
      particleCount: 100,
      spread: 70,
      origin: { y: 0.6 }
    });
  };

  const countries = [
    "Afghanistan", "Albania", "Algeria", "American Samoa", "Andorra", "Angola", "Anguilla", "Antigua & Barbuda", 
    "Argentina", "Armenia", "Aruba", "Australia", "Austria", "Azerbaijan", "Bahamas", "Bahrain", "Bangladesh", 
    "Barbados", "Belarus", "Belgium", "Belize", "Benin", "Bermuda", "Bhutan", "Bolivia", "Bonaire", 
    "Bosnia & Herzegovina", "Botswana", "Brazil", "British Indian Ocean Ter", "Brunei", "Bulgaria", "Burkina Faso", 
    "Burundi", "Cambodia", "Cameroon", "Canada", "Canary Islands", "Cape Verde", "Cayman Islands", 
    "Central African Republic", "Chad", "Channel Islands", "Chile", "China", "Christmas Island", "Cocos Island", 
    "Colombia", "Comoros", "Congo", "Cook Islands", "Costa Rica", "Cote D'Ivoire", "Croatia", "Cuba", "Curacao", 
    "Cyprus", "Czech Republic", "Denmark", "Djibouti", "Dominica", "Dominican Republic", "East Timor", "Ecuador", 
    "Egypt", "El Salvador", "Equatorial Guinea", "Eritrea", "Estonia", "Ethiopia", "Falkland Islands", "Faroe Islands", 
    "Fiji", "Finland", "France", "French Guiana", "French Polynesia", "French Southern Ter", "Gabon", "Gambia", 
    "Georgia", "Germany", "Ghana", "Gibraltar", "Great Britain", "Greece", "Greenland", "Grenada", "Guadeloupe", 
    "Guam", "Guatemala", "Guinea", "Guyana", "Haiti", "Hawaii", "Honduras", "Hong Kong", "Hungary", "Iceland", 
    "India", "Indonesia", "Iran", "Iraq", "Ireland", "Isle of Man", "Israel", "Italy", "Jamaica", "Japan", "Jordan", 
    "Kazakhstan", "Kenya", "Kiribati", "Kosovo", "Korea North", "Korea South", "Kuwait", "Kyrgyzstan", "Laos", 
    "Latvia", "Lebanon", "Lesotho", "Liberia", "Libya", "Liechtenstein", "Lithuania", "Luxembourg", "Macau", 
    "Macedonia", "Madagascar", "Malaysia", "Malawi", "Maldives", "Mali", "Malta", "Marshall Islands", "Martinique", 
    "Mauritania", "Mauritius", "Mayotte", "Mexico", "Midway Islands", "Moldova", "Monaco", "Mongolia", "Montserrat", 
    "Morocco", "Mozambique", "Myanmar", "Nambia", "Nauru", "Nepal", "Netherland Antilles", "Netherlands (Holland, Europe)", 
    "Nevis", "New Caledonia", "New Zealand", "Nicaragua", "Niger", "Nigeria", "Niue", "Norfolk Island", "Norway", 
    "Oman", "Pakistan", "Palau Island", "Palestine", "Panama", "Papua New Guinea", "Paraguay", "Peru", "Philippines", 
    "Pitcairn Island", "Poland", "Portugal", "Puerto Rico", "Qatar", "Republic of Montenegro", "Republic of Serbia", 
    "Reunion", "Romania", "Russia", "Rwanda", "St Barthelemy", "St Eustatius", "St Helena", "St Kitts-Nevis", "St Lucia", 
    "St Maarten", "St Pierre & Miquelon", "St Vincent & Grenadines", "Saipan", "Samoa", "Samoa American", "San Marino", 
    "Sao Tome & Principe", "Saudi Arabia", "Senegal", "Serbia", "Seychelles", "Sierra Leone", "Singapore", "Slovakia", 
    "Slovenia", "Solomon Islands", "Somalia", "South Africa", "Spain", "Sri Lanka", "Sudan", "Suriname", "Swaziland", 
    "Sweden", "Switzerland", "Syria", "Tahiti", "Taiwan", "Tajikistan", "Tanzania", "Thailand", "Togo", "Tokelau", 
    "Tonga", "Trinidad & Tobago", "Tunisia", "Turkey", "Turkmenistan", "Turks & Caicos Is", "Tuvalu", "Uganda", 
    "Ukraine", "United Arab Emirates", "United Kingdom", "United States of America", "Uruguay", "Uzbekistan", "Vanuatu", 
    "Vatican City State", "Venezuela", "Vietnam", "Virgin Islands (Brit)", "Virgin Islands (USA)", "Wake Island", 
    "Wallis & Futana Is", "Yemen", "Zaire", "Zambia", "Zimbabwe"
  ];

  return (
    <div className="container">
      <h1 className="title">Lease License Contract Generator</h1>
      <div className="tab-container">
        <div className={`tab ${step === 1 ? 'tab-active' : ''}`} onClick={() => setStep(1)}>1. Beat Information</div>
        <div className={`tab ${step === 2 ? 'tab-active' : ''}`} onClick={() => setStep(2)}>2. Producer Information</div>
        <div className={`tab ${step === 3 ? 'tab-active' : ''}`} onClick={() => setStep(3)}>3. Artist Information</div>
        <div className={`tab ${step === 4 ? 'tab-active' : ''}`} onClick={() => setStep(4)}>4. Download</div>
      </div>

      {step === 1 && (
        <div>
          <div className="form-group">
            <label className="label">What is your beat's name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., Summer Vibes"
              value={beatInfo.name}
              onChange={(e) => setBeatInfo({ ...beatInfo, name: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Lease Price (USD):</label>
            <input
              type="number"
              className="input-field"
              placeholder="e.g., 50"
              value={beatInfo.leasePrice}
              onChange={(e) => setBeatInfo({ ...beatInfo, leasePrice: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Number of Copies Allowed:</label>
            <input
              type="number"
              className="input-field"
              placeholder="e.g., 2500"
              value={beatInfo.copies}
              onChange={(e) => setBeatInfo({ ...beatInfo, copies: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Producer's Publishing Rights (%):</label>
            <input
              type="number"
              className="input-field"
              placeholder="e.g., 50"
              value={beatInfo.publishingRights}
              onChange={(e) => setBeatInfo({ ...beatInfo, publishingRights: e.target.value })}
            />
          </div>
          <div className="navigation">
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

      {step === 2 && (
        <div>
          <div className="form-group">
            <label className="label">What is your legal name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., John Doe"
              value={producerInfo.legalName}
              onChange={(e) => setProducerInfo({ ...producerInfo, legalName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your producer name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., DJ Awesome"
              value={producerInfo.producerName}
              onChange={(e) => setProducerInfo({ ...producerInfo, producerName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your address?</label>
            <textarea
              className="address-field"
              placeholder="e.g., 123 Beat Street, Apt 4"
              value={producerInfo.address}
              onChange={(e) => setProducerInfo({ ...producerInfo, address: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is your state?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., California"
              value={producerInfo.state}
              onChange={(e) => setProducerInfo({ ...producerInfo, state: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Please select your country.</label>
            <select
              className="select-field"
              value={producerInfo.country}
              onChange={(e) => setProducerInfo({ ...producerInfo, country: e.target.value })}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

      {step === 3 && (
        <div>
          <div className="form-group">
            <label className="label">What is the artist's legal name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., Jane Smith"
              value={artistInfo.legalName}
              onChange={(e) => setArtistInfo({ ...artistInfo, legalName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is the artist's stage name?</label>
            <input
              type="text"
              className="input-field"
              placeholder="e.g., MC Cool"
              value={artistInfo.stageName}
              onChange={(e) => setArtistInfo({ ...artistInfo, stageName: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">What is the artist's address?</label>
            <textarea
              className="address-field"
              placeholder="e.g., 456 Rhythm Road, Suite 7"
              value={artistInfo.address}
              onChange={(e) => setArtistInfo({ ...artistInfo, address: e.target.value })}
            />
          </div>
          <div className="form-group">
            <label className="label">Please select the artist's country.</label>
            <select
              className="select-field"
              value={artistInfo.country}
              onChange={(e) => setArtistInfo({ ...artistInfo, country: e.target.value })}
            >
              {countries.map((country, index) => (
                <option key={index} value={country}>{country}</option>
              ))}
            </select>
          </div>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
            <button className="button" onClick={handleNextStep}>Next</button>
          </div>
        </div>
      )}

{step === 4 && (
        <div className="download-section">
          <div className="form-group">
            <label className="label">Upload Logo (Optional)</label>
            <input type="file" className="input-file" onChange={handleLogoUpload} accept="image/*" />
            {logoPreview && (
              <div className="logo-preview">
                <img src={logoPreview} alt="Logo Preview" style={{ maxWidth: '200px', maxHeight: '100px', marginTop: '10px' }} />
              </div>
            )}
          </div>
          <div className="disclaimer">
            <h3>Disclaimer</h3>
            <p>
              This agreement is provided for informational purposes only and does not constitute legal advice. 
              It is recommended that you consult with a qualified attorney before entering into any legal agreement. 
              By using this contract generator, you acknowledge that you understand the terms and conditions 
              outlined in the agreement and assume full responsibility for its use.
            </p>
          </div>
          <div className="agree-section">
            <input 
              type="checkbox" 
              id="agree" 
              checked={agreed} 
              onChange={(e) => setAgreed(e.target.checked)} 
            />
            <label htmlFor="agree">I have read and agree to the terms and conditions.</label>
          </div>
          <div className="form-group signature-section">
            <label className="label">Signature</label>
            <SignatureCanvas ref={signatureCanvasRef} canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }} />
            <button className="button" onClick={clearSignature}>Clear</button>
          </div>
          <button className="button download-button" onClick={generatePDF} disabled={!agreed}>Download PDF</button>
          <div className="navigation">
            <button className="button" onClick={handlePrevStep}>Previous</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default LeaseContractGenerator;